<template>
<div>
  
                        

      <div class="rounded-lg shadow border border-white bg-white">

            <div class="row justify-content-centre align-items-center">    
               
            <h4 class="col ml-3 font-weight-lighter text-info"> <span class="fa fa-user-cog"></span> Gestion Des Utilisateurs    </h4> 
               
             
<div class="col">
                <b-button pill size="sm"  class="m-3 float-right" variant="success" @click="add_click($event.target)">
                            <i class="fa fa-plus-square"></i>
                    <span> Ajouter</span> 
                </b-button>

                <b-button pill size="sm"  class="m-3 float-right" variant="info" @click="fetchList">
                    <i class="fa fa-sync-alt"></i>
                    <span> Actualiser</span> 
                </b-button>
</div>
            </div>
       



        <b-table 
        sort-icon-left
        head-variant="light"

        :fields="fields" 
        :items="itemslist"
        >
            <template #cell(actions)="row">
                    <b-button pill size="sm"  class="mr-2 float-right" variant="outline-danger" @click="del_click(row.item)">
                                <i class="fa fa-trash-alt"></i>
                        
                    </b-button>

                    <b-button pill size="sm" class="mr-2 float-right" variant="outline-secondary" @click="edit_click(row.item, row.index, $event.target)">
                            <i class="fa fa-pencil-alt"></i>
                    
                    </b-button>

                    <b-button pill size="sm" class="mr-2 float-right" variant="outline-primary" @click="fetchListDroits(row.item.id)">
                            <i class="fa fa-tasks"></i>
                    
                    </b-button>
                </template>
        
        </b-table>
      </div>


    <!-- le modal -->
    <b-modal id="banqModal" title='Ajouter/ Modifier' size="lg" hide-footer>
           <form @submit.prevent="handleSubmit">

               <div class="row">
                            <div class="form-group col">
                                <label for="name">Nom ET Prénom :</label>
                                <input :disabled="ModalEdit" type="text" v-model="itemForm.name" id="name" name="name" class="form-control"
                                    :class="{ 'is-invalid': isSubmitted && $v.itemForm.name.$error }" />
                                <div v-if="isSubmitted && !$v.itemForm.name.required" class="invalid-feedback">Ce champ est obligatoire !!</div>
                            </div>

                            <div class="form-group col">
                                <label for="email">Email :</label>
                                <input :disabled="ModalEdit" type="text" v-model="itemForm.email" id="email" name="email" class="form-control"
                                    :class="{ 'is-invalid': isSubmitted && $v.itemForm.email.$error }" />
                                <div v-if="isSubmitted && !$v.itemForm.email.required" class="invalid-feedback">Ce champ est obligatoire !!</div>
                            </div>
               </div>

               <div class="row">
                        <div class="form-group col">
                            <label for="password">Mot De Passe :</label>
                            <input type="password" v-model="itemForm.password" id="password" name="password" class="form-control"
                                :class="{ 'is-invalid': isSubmitted && $v.itemForm.password.$error }" />
                            <div v-if="isSubmitted && !$v.itemForm.password.required" class="invalid-feedback">Ce champ est obligatoire !!</div>
                        </div>

                        <div class="form-group col">
                            <label for="password_confirmation">Confirmation Mot De Passe :</label>
                            <input type="password" v-model="itemForm.password_confirmation" id="password_confirmation" name="password_confirmation" class="form-control"
                                :class="{ 'is-invalid': isSubmitted && $v.itemForm.password_confirmation.$error }" />
                            <div v-if="isSubmitted && !$v.itemForm.password_confirmation.required" class="invalid-feedback">Ce champ est obligatoire !!</div>
                            <div v-if="isSubmitted && !$v.itemForm.password_confirmation.sameAsPassword"  class="invalid-feedback">Mot De Passe doit être identique !!</div>
                        </div>
               </div>

               <div class="row">
                            <div class="form-group col">

                                                <label for="type">Type :</label>
                                                <select  class="form-control" id="type" v-model="itemForm.type"
                                                :class="{ 'is-invalid': isSubmitted && $v.itemForm.type.$error }">

                                                <option value=1>Employé</option>
                                                <option value=2>Distribution/Prointeuse</option>

                                                </select>

                                                <div v-if="isSubmitted && !$v.itemForm.type.required" class="invalid-feedback">Ce champ est obligatoire !!</div>

                                </div>

                                <div class="form-group col">

                                                <label for="dep">Dépôt :</label>
                                                <select  class="form-control" id="dep" v-model="itemForm.dep"
                                                :class="{ 'is-invalid': isSubmitted && $v.itemForm.dep.$error }">
                                                <option v-for="item in depots" :key="item.id" v-bind:value="item.id">   
                                                    {{ item.nom }}
                                                </option>
                                                <option value=0>Tous</option>
                                                </select>

                                                <div v-if="isSubmitted && !$v.itemForm.dep.required" class="invalid-feedback">Ce champ est obligatoire !!</div>

                                </div>

               </div>




  



            <div class="form-group">
                <button type="submit" class="btn  btn-primary btn-block">{{ModalType}}</button>
            </div>


        </form>
    </b-modal>




    <!-- le modal -->
    <b-modal id="droitModal" title="Droits de l'utilisateur" size="lg" hide-footer>

            <div class="form-group">
                <button @click="InitDroits(0)" class="btn btn-outline-secondary btn-block">Initialiser Les Droits Avec Aucun Accès <span class=" text-success">(Recommander)</span></button>
            </div>
            <div class="form-group">
                <button @click="InitDroits(1)" class="btn btn-outline-secondary btn-block">Initialiser Les Droits Avec Un Accès Total <span class=" text-danger">(Attention)</span></button>
            </div>

        <b-tabs content-class="mt-3" justified>

                <b-tab title="Parties" active>
            <b-table 
                small 
                borderless
                
                striped 
                sticky-header
                :fields="fieldsDroits" 
                :items="itemParties"
                >

            <template #cell(access)="data">


                                <b-form-checkbox v-model="data.value" switch @change="UpdateRole_click(data.item)"></b-form-checkbox>
            </template>



                </b-table>
            </b-tab>

            <b-tab title="Général">
            <b-table 
                small 
                borderless
                
                striped 
                sticky-header
                :fields="fieldsDroits" 
                :items="itemParams"
                >

            <template #cell(access)="data">


                                <b-form-checkbox v-model="data.value" switch @change="UpdateRole_click(data.item)"></b-form-checkbox>
            </template>



                </b-table>
            </b-tab>



            <b-tab title="Stock">
                    <b-table 

                borderless
                striped 
                small
                sticky-header
                :fields="fieldsDroits" 
                :items="itemStockRol"
                >

            <template #cell(access)="data">


                                <b-form-checkbox v-model="data.value" switch @change="UpdateRole_click(data.item)"></b-form-checkbox>
            </template>



                </b-table>
            </b-tab>

            <b-tab title="Achat">
                    <b-table 

                borderless
                striped 
                small
                sticky-header
                :fields="fieldsDroits" 
                :items="itemAchatRol"
                >

            <template #cell(access)="data">


                                <b-form-checkbox v-model="data.value" switch @change="UpdateRole_click(data.item)"></b-form-checkbox>
            </template>



                </b-table>
            </b-tab>


            <b-tab title="Ventes">
            <b-table 
                small 
                borderless
                
                striped 
                sticky-header
                :fields="fieldsDroits" 
                :items="itemVentRol"
                >

            <template #cell(access)="data">


                                <b-form-checkbox v-model="data.value" switch @change="UpdateRole_click(data.item)"></b-form-checkbox>
            </template>



                </b-table>
            </b-tab>


            <b-tab title="POS">
                    <b-table 

                borderless
                striped 
                small
                sticky-header
                :fields="fieldsDroits" 
                :items="itemPosRol"
                >

            <template #cell(access)="data">


                                <b-form-checkbox v-model="data.value" switch @change="UpdateRole_click(data.item)"></b-form-checkbox>
            </template>
                </b-table>

            </b-tab>


                <b-tab title="Distribution">
                    <b-table 

                borderless
                striped 
                small
                sticky-header
                :fields="fieldsDroits" 
                :items="itemChargRol"
                >

            <template #cell(access)="data">


                                <b-form-checkbox v-model="data.value" switch @change="UpdateRole_click(data.item)"></b-form-checkbox>
            </template>



                </b-table>
            </b-tab>


                <b-tab title="Finance">
                <b-table 

                borderless
                striped
                small
                sticky-header
                :fields="fieldsDroits" 
                :items="itemFinancRol"
                >

            <template #cell(access)="data">


                                <b-form-checkbox v-model="data.value" switch @change="UpdateRole_click(data.item)"></b-form-checkbox>
            </template>



                </b-table>
            </b-tab>




                <b-tab title="Ressources Humaines">
                    <b-table 

                borderless
                striped 
                small
                sticky-header
                :fields="fieldsDroits" 
                :items="itemRhRol"
                >

            <template #cell(access)="data">


                                <b-form-checkbox v-model="data.value" switch @change="UpdateRole_click(data.item)"></b-form-checkbox>
            </template>
                </b-table>
            </b-tab>

                <b-tab title="Production">
                    <b-table 

                borderless
                striped 
                small
                sticky-header
                :fields="fieldsDroits" 
                :items="itemProdRol"
                >

                <template #cell(access)="data">
                                    <b-form-checkbox v-model="data.value" switch @change="UpdateRole_click(data.item)"></b-form-checkbox>
                </template>
                </b-table>
            </b-tab>

                <b-tab title="Gestion Des Projets">
                    <b-table 

                borderless
                striped 
                small
                sticky-header
                :fields="fieldsDroits" 
                :items="itemProjetRol"
                >

                <template #cell(access)="data">
                                    <b-form-checkbox v-model="data.value" switch @change="UpdateRole_click(data.item)"></b-form-checkbox>
                </template>
                </b-table>
            </b-tab>

        </b-tabs>


    </b-modal>
    <!-- les trucs public -->

    <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true" color="#0179F9" loader='bars'></loading>


</div>
</template>


          
   
           



<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import {required,sameAs} from "vuelidate/lib/validators";

export default {
  computed: {
    user_name() {

      if(this.$store.getters.get_user)
      {
            return this.$store.getters.get_user.name;
      }
      else
      {
            return '';
      }
    },


  },
    mounted(){
        this.fetchList()
        this.fetchDep()
    },
	data () {
		return {

            itemVentRol:[],
            itemStockRol: [],
            itemAchatRol:[],
            itemFinancRol: [],
            itemChargRol:[],
            itemParties:[],
            itemParams:[],
            itemPosRol:[],
            itemRhRol:[], 
            itemProdRol:[], 
            itemProjetRol:[],

            itemslistDroits: [],
            fieldsDroits: [
				{key: 'description', label: ''},
                {key: 'access', label: '', 
                 formatter: (value) => {
                 return value ? true : false
                }},
                { key: 'actions', label: '' }
            ],
            itemslist: [],
            itemForm: {
                    id:'',
                    name:'',
                    email:'',
                    password:'',
                    password_confirmation:'',
                    type:'',
                    dep:''},
            fields: [
				{key: 'name', label: 'Nom', sortable: true},
                {key: 'email', label: 'Email', sortable: true},
                {key: 'type', label: 'Type', 
                formatter: (value) => {
                        if(value==1)
                        {
                            return 'Employé'
                        }
                        else if(value==2)
                        {
                            return 'Distribution'
                        }

                }, sortable: true},

                { key: 'actions', label: '' }
            ],
            depots:[],
            //public trucs
            ModalEdit: false,
            ModalType:'Ajouter',
            isSubmitted: false,
            isLoading: false
		}
	},
    validations: {
            itemForm: {
                name: {
                    required
                },
                email: {
                    required
                },
                password: {
                    required
                },
                password_confirmation: {
                    required,
                    sameAsPassword: sameAs('password')
                },
                type: {
                    required
                },
                dep: {
                    required
                }
            }
    },
    methods :{



        UpdateRole_click(row)
        {


                        let acc=0;

                        if(row.access==0)
                        {
                            acc=1
                        }
                        else
                        {
                        acc=0
                        }


                    this.isLoading=true;

                    this.$http.patch('/userDroits/'+row.id,{
                    access:acc,
                    droit_id:row.droit_id,
                    user_id:row.user_id
                        })
                        .then(response => {

                            this.isLoading=false;


                            let index = this.itemslistDroits.findIndex(item => item.id === row.id)
                            this.itemslistDroits.splice(index, 1,response.data.data)
                            
                            this.flashMessage.show({
                            status: 'success',
                            title: 'Données modifiées',
                            message: 'Les données sont modifiées correctement'
                            })


                            })
                            .catch(error=> {console.log(error)
                            this.isLoading=false;


                            this.flashMessage.show({
                            status: 'error',
                            title: 'Erreur De Modification',
                            message: 'Impossible  de modifier les données .. Réessayez !!'
                        })
                        });
        },
        fetchListDroits(row_bn_id)
        {

this.itemForm.id = row_bn_id


                   this.isLoading=true;
                    this.$http.get('/droitsUser/'+row_bn_id)
                    .then((result) => {
                                this.itemslistDroits = result.data.data


        this.itemVentRol= this.itemslistDroits.filter(function (el)  {
            return el.type==10
      })

        this.itemStockRol= this.itemslistDroits.filter(function (el)  {
            return el.type==2
      })


        this.itemAchatRol= this.itemslistDroits.filter(function (el)  {
            return el.type==3
      })

        this.itemFinancRol= this.itemslistDroits.filter(function (el)  {
            return el.type==4
      })

              this.itemChargRol= this.itemslistDroits.filter(function (el)  {
            return el.type==5
      })

              this.itemParties= this.itemslistDroits.filter(function (el)  {
            return el.type==7
      })


      this.itemPosRol= this.itemslistDroits.filter(function (el)  {
            return el.type==8
      })
            this.itemParams= this.itemslistDroits.filter(function (el)  {
            return el.type==13
      })


                    this.itemRhRol= this.itemslistDroits.filter(function (el)  {
            return el.type==9
      })

                    this.itemProdRol= this.itemslistDroits.filter(function (el)  {
            return el.type==11
      })
                    this.itemProjetRol= this.itemslistDroits.filter(function (el)  {
            return el.type==19
      })



                                this.isLoading=false;

                                this.$root.$emit('bv::show::modal', 'droitModal')

                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    }); 


        },
        InitDroits(acc)
        {
                        this.isLoading=true;
                    this.$http.post("/genererDroits", {
                    user_id: this.itemForm.id,
                    access:acc
                    })
                .then(response => {

                    this.isLoading=false;
                //    this.itemslist.unshift(response.data.user);
                 console.log(response.data);


                    this.$bvModal.hide('droitModal')


                    //afficher message
                    this.flashMessage.show({
                    status: 'success',
                    title: 'Données sauvegardées',
                    message: 'Les données sont sauvegardées correctement '
                })




                //rechrger le truc des droits:

                this.fetchListDroits(this.itemForm.id)

                })
                .catch(error=> {console.log(error)
                this.isLoading=false;


               this.flashMessage.show({
                status: 'error',
                title: 'Erreur De Sauvegarde',
                message: 'Impossible  de sauvegarder les données .. Réessayez !!'
                })
                });
        },
        itemslisttore(){
                this.isLoading=true;
                this.$http.post("/auth/register", {
                    name: this.itemForm.name,
                    email: this.itemForm.email,
                    password: this.itemForm.password,
                    password_confirmation: this.itemForm.password_confirmation,
                    type: this.itemForm.type,
                    dep: this.itemForm.dep,
                    })
                .then(response => {

                    this.isLoading=false;
                    this.itemslist.unshift(response.data.user);
                    //console.log(response.data.user);

                    this.$bvModal.hide('banqModal')

                    //afficher message
                    this.flashMessage.show({
                    status: 'success',
                    title: 'Données sauvegardées',
                    message: 'Les données sont sauvegardées correctement '
                })


                })
                .catch(error=> {console.log(error)
                this.isLoading=false;


               this.flashMessage.show({
                status: 'error',
                title: 'Erreur De Sauvegarde',
                message: 'Impossible  de sauvegarder les données .. Réessayez !!'
                })
                });

        },
        banqueEdit(){

            this.isLoading=true;

            this.$http.patch('/auth/userupdate/'+this.itemForm.id,{
                    name: this.itemForm.name,
                    email: this.itemForm.email,
                    password: this.itemForm.password,
                    password_confirmation: this.itemForm.password_confirmation,
                    type: this.itemForm.type,
                    dep: this.itemForm.dep,

                })
                .then(response => {

                    this.isLoading=false;
                    console.log(response.data)
                    this.$bvModal.hide('banqModal')

                    
                    this.flashMessage.show({
                    status: 'success',
                    title: 'Données modifiées',
                    message: 'Les données sont modifiées correctement'
                    })

                    //recharger le truc
                    this.fetchList()

                    })
                    .catch(error=> {console.log(error)
                    this.isLoading=false;


                    this.flashMessage.show({
                    status: 'error',
                    title: 'Erreur De Modification',
                    message: 'Impossible  de modifier les données .. Réessayez !!'
                })
                });

        },
        banqueDell(){

            this.isLoading=true;

            this.$http.delete('/dellUser/'+this.itemForm.id)
                .then(response => {
                console.log(response.data)
                this.isLoading=false;



                    
                this.flashMessage.show({
                status: 'success',
                title: 'Données Supprimées',
                message: 'Les données sont supprimées correctement'
                })


                    let index = this.itemslist.findIndex(item => item.id === this.itemForm.id)
                    this.itemslist.splice(index, 1)




                    })
                .catch(error=> {console.log(error)
                this.isLoading=false;


                this.flashMessage.show({
                status: 'error',
                title: 'Erreur De Suppression',
                message: 'Impossible  de supprimer les données .. Réessayez !!'
                })
                });

        },
        add_click(button) {
        this.itemForm.id=''
        this.itemForm.name=''
        this.itemForm.email=''
        this.itemForm.password=''
        this.itemForm.password_confirmation=''
        this.itemForm.type=1
        this.itemForm.dep=0

        this.ModalEdit=false;
        this.ModalType='Ajouter'

        this.$root.$emit('bv::show::modal', 'banqModal', button)
      },
        edit_click(item, index, button) {


                this.$confirm("Voulez-vous vraiment modifier cet utilisateur ??")
                 .then(() => {
                this.itemForm.id=item.id
                this.itemForm.name=item.name
                this.itemForm.email=item.email
                this.itemForm.password=''
                this.itemForm.password_confirmation=''
                this.itemForm.type=item.type
                this.itemForm.dep=item.dep

                this.ModalEdit=true;
                this.ModalType='Modifier'
               this.$root.$emit('bv::show::modal', 'banqModal', button)
                })
                .catch(error=> {
                console.log(error)

                });


      },
        del_click(item) {

                this.$confirm("Voulez-vous vraiment supprimer cet élément ??")
                 .then(() => {
                this.itemForm.id=item.id
                                    this.banqueDell()
                                })
                                .catch(error=> {
                                console.log(error)

                                    });




      },
      fetchDep: function () {

                                        //chager les depots:
                    this.isLoading=true;
                    this.$http.get('/depots')
                    .then((result) => {
                                this.depots = result.data.data
                                this.isLoading=false;
                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });
      },
      fetchList: function () {
                    this.isLoading=true;
                    this.$http.get('/getUsersList')
                    .then((result) => {
                                this.itemslist = result.data.data
                                this.isLoading=false;
                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });


        },
        handleSubmit() {
                this.isSubmitted = true;

                this.$v.$touch();
                if (this.$v.$invalid) {
                this.flashMessage.show({
                status: 'info',
                title: 'Vérifiez les données et Reésayez !!',
                })
                }
                else 
                {


                            if(this.ModalEdit)
                            {
                                this.banqueEdit()
                            }
                            else
                            {

                                this.itemslisttore()
                            }   

                }

            }

    },
    components: {
              Loading      
    }
}
</script>

<style  scoped>

</style>