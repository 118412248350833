<template>
<div class="p-4 back_princ">

<div class=" d-flex justify-content-center text-secondary">
 <i class="fas fa-file-alt fa-4x"></i>
</div>

<div class=" d-flex justify-content-center text-secondary mb-4">
 <h2 class="font-weight-lighter">Rapports Des Ventes</h2>
</div>



<div v-if="d_gst_bl_client==true || d_gst_br_client==true" class=" row p-4">

            <!-- Earnings (Monthly) Card Example  -->
            <div class="col">
              <div class="btn card border border-light shadow h-100 py-2" @click="click_list">
                <div class="card-body">
                  <div class="row no-gutters align-items-center">
                    <div class="col mr-2">
                      <div class="font-weight-bold text-secondary  text-uppercase ">Liste Des Bons</div>
                    </div>
                    <div class="col-auto">
                      <i class="fas fa-clipboard-list fa-2x text-secondary"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>


                        <!-- Earnings (Monthly) Card Example  -->
            <div class="col">
              <div class="btn card border border-light shadow h-100 py-2" @click="click_art">
                <div class="card-body">
                  <div class="row no-gutters align-items-center">
                    <div class="col mr-2">
                      <div class="text-xs font-weight-bold text-secondary text-uppercase">Liste Des Articles</div>
                    </div>
                    <div class="col-auto">
                      <i class="fas fa-clipboard-list fa-2x text-secondary"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>

</div>



<div  v-if="d_gst_fact==true" class=" row p-4">

              <!-- Earnings (Monthly) Card Example -->
            <div class="col">
              <div class="btn card border border-light shadow h-100 py-2" @click="click_listfac">
                <div class="card-body">
                  <div class="row no-gutters align-items-center">
                    <div class="col mr-2">
                      <div class="text-xs font-weight-bold text-secondary text-uppercase">Liste Des Factures</div>
                    </div>
                    <div class="col-auto">
                      <i class="fas fa-clipboard-list  fa-2x text-secondary"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Earnings (Monthly) Card Example -->
            <div class="col">
              <div class="btn card border border-light shadow h-100 py-2" @click="click_Qtefac">
                <div class="card-body">
                  <div class="row no-gutters align-items-center">
                    <div class="col mr-2">
                      <div class="font-weight-bold text-secondary  text-uppercase ">Quantités Des Articles (Facturés)</div>
                    </div>
                    <div class="col-auto">
                      <i class="fas fa-clipboard-list fa-2x text-secondary"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>

</div>





  </div>
</template>

<script>
export default {
computed:{
      d_gst_bl_client() {
      return this.$store.getters.get_gst_bl_client;
    },
    d_gst_br_client() {
      return this.$store.getters.get_gst_br_client;
    },
    d_gst_fact() {
      return this.$store.getters.get_gst_fact;
    },
},
 methods:{
       click_art()
      {
                      //goto first page: 
                    this.$router.push({ 
                        name: 'ventesRrtrClientsQteRapp'

                    });
      },
      click_list()
      {
                       //goto first page:
                    this.$router.push({ 
                        name: 'ventesRrtrClientsRapp'

                    });
      },
       click_listfac()
      {
                      //goto first page:
                    this.$router.push({ 
                        name: 'factureBonRapp'

                    });
      },
      click_Qtefac()
      {
                       //goto first page:
                    this.$router.push({ 
                        name: 'factureQteRapp'

                    });
      },

 }
}
</script>
<style scoped>
.back_princ{
  background: #eff7fa;
}

.text_org{
  color: green;
}
</style>