<template>
<div>
  
            <div class="rounded-lg shadow border border-white bg-white mb-4">
                        <div class="row justify-content-centre align-items-center">    

              <div class="col">
  





        <form @submit.prevent="handleSubmitBonCMD()">
                <div class="input-group  border border-white rounded bg-white p-2  justify-content-centre align-items-center text-info">

                <label for="camion_id" class="mr-sm-2 pt-2"> Camion :</label>

                <select class="form-control border rounded" id="camion_id" v-model="itemFormBon.camion_id"
                        :class="{ 'is-invalid': isSubmitted && $v.itemFormBon.camion_id.$error }">

                        <option v-for="item in camionsList" :key="item.id" v-bind:value="item.id">   
                            {{ item.matricul }}
                        </option>

                                            
                    <option value=0>Tous</option>

                </select>


        
                <label for="dateDebut" class="mr-sm-2  pt-2 pl-1"> De :</label>
                    <b-form-datepicker
                    class="mr-sm-2 border rounded"
                    id="datepicker-dateDebut"
                    placeholder="date fin"
                    v-model="itemFormBon.dateDebut"
                    :date-format-options="{day: 'numeric' , month: 'numeric',year: 'numeric' }"
                    locale="fr" />
        

                       
                <label for="dateFin" class="mr-sm-2  pt-2 pl-1"> Au :</label>
                    <b-form-datepicker
                    class="mr-sm-2 border rounded"
                    id="datepicker-dateFin"
                    placeholder="date fin"
                    v-model="itemFormBon.dateFin"
                    :date-format-options="{day: 'numeric' , month: 'numeric',year: 'numeric' }"
                    locale="fr" />
      



                                    <div class="input-group-prepend">
                                        <button class="btn  btn-info rounded-pill ml-1" type="submit" id="button-addon1">   
                                        <span class="fa fa-search"></span> Liste
                                        </button>
                                    </div>

                                

                </div>
        </form>





            </div>
                        </div>          
            </div>  



 
      <div class="rounded-lg shadow border border-white bg-white">
            <div class="row justify-content-centre align-items-center">    

            <h4 class="col ml-3 font-weight-lighter text-info"> <span class="fa fa-file-alt"></span> Suivi Des Camions</h4> 

                    <div class="col">

                                    <b-button pill size="sm"  class="m-2 float-right" variant="info" @click="exportDox">
                                        <i class="fa fa-print"></i>
                                        <span> Rapport</span> 
                                    </b-button>

                    </div>
            </div>
       

<div class="row justify-content-centre">

<div class="col">

                <div class=" d-flex justify-content-center pt-1 text-secondary">
                <h5 class="font-weight-light ">Km / Gazoil </h5>
                </div>
        <b-table 

        id="main-table"
        :per-page="perPage"
        :current-page="currentPage"
        responsive  
        sort-icon-left
        head-variant="light"

        :fields="fields" 
        :items="itemslist"
        >
      
        </b-table>

                        <b-pagination
                pills
                v-model="currentPage"
                :total-rows="rows_pagin"
                :per-page="perPage"
                aria-controls="main-table"
                align="center"
                ></b-pagination>
</div>


<div class="col">
                <div class=" d-flex justify-content-center pt-1 text-secondary">
                <h5 class="font-weight-light ">Maintenances </h5>
                </div>

            <b-table 

        id="main-tablem"
        :per-page="perPagem"
        :current-page="currentPagem"
        responsive  
        sort-icon-left
        head-variant="light"

        :fields="fieldsMaint" 
        :items="itemslistMaint"
        >
      
        </b-table>

                        <b-pagination
                pills
                v-model="currentPagem"
                :total-rows="rows_paginm"
                :per-page="perPagem"
                aria-controls="main-tablem"
                align="center"
                ></b-pagination>
</div>

</div>


<div class="row justify-content-centre align-items-center">    

            <h5 class="col ml-3 font-weight-lighter text-info">  Total Gazoil ( L ) : {{total_l}}</h5>

            <h5 class="col ml-3 font-weight-lighter text-info">  Total Gazoil ( DH ): {{total_dh}}</h5> 

            <h5 class="col ml-3 font-weight-lighter text-info">  Total Maintenances ( DH ) : {{total_m}}</h5>
 </div>

      </div>





    <!-- les trucs public -->
    <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true" color="#0179F9" loader='bars'></loading>


</div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import {required} from "vuelidate/lib/validators";
 import { HeadingLevel,PageNumber, PageNumberFormat,Footer, Header, Table,TableCell,TableRow,Document, Paragraph,WidthType,VerticalAlign,AlignmentType, Packer, TextRun } from "docx";
 import { saveAs } from 'file-saver';

export default {
      computed: {
              entet_pag() {

      if(this.$store.getters.get_entete)
      {
            return this.$store.getters.get_entete;
      }
      else
      {
            return '';
      }
    },
    sousentet_pag() {

      if(this.$store.getters.get_sousentete)
      {
            return this.$store.getters.get_sousentete;
      }
      else
      {
            return '';
      }
    },
    pied_pag() {

      if(this.$store.getters.get_pied)
      {
            return this.$store.getters.get_pied;
      }
      else
      {
            return '';
      }
    },
      rows_pagin() {
        return this.itemslist.length
      },
      rows_paginm() {
        return this.itemslistMaint.length
      },
     user_name() {

      if(this.$store.getters.get_user)
      {
            return this.$store.getters.get_user.name;
      }
      else
      {
            return '';
      }
    },

    total_dh: function() {
      return this.itemslist.reduce((a, b) => +a + +b.carbur_dh, 0);
    },
    total_l: function() {
      return this.itemslist.reduce((a, b) => +a + +b.carbur_l, 0);
    },
    total_m: function() {
      return this.itemslistMaint.reduce((a, b) => +a + +b.prix, 0);
    },

    },
    mounted(){
      this.fetchCentre()
    },
	data () {
		return {
            perPage: 10,
            currentPage: 1,

            perPagem: 10,
            currentPagem: 1,

            itemFormBon: {
                    camion_id:'',
                    centre_nom:'',
                    dateDebut:new Date().toJSON().slice(0,10).replace(/-/g,'-'),
                    dateFin:new Date().toJSON().slice(0,10).replace(/-/g,'-'),
                    is_matin:''
            },

            camionsList:[],
            itemslist: [],
            fields: [
                {key: 'matricul', label: 'Matricul', sortable: true},
				{key: 'date', label: 'Date', sortable: true},
                {key: 'kilom_depart', label: 'Km D', sortable: true},
                {key: 'kilom_arrive', label: 'Km A', sortable: true},
                {key: 'carbur_l', label: 'Gaz (L)', sortable: true},
                {key: 'carbur_dh', label: 'Gaz (DHs)', sortable: true}
            ],
            itemslistMaint:[],

            fieldsMaint: [
            {key: 'matricul', label: 'Matricul', sortable: true},
				{key: 'date', label: 'Date', sortable: true},
                {key: 'prix', label: 'Prix', sortable: true},
                {key: 'description', label: 'Description', sortable: true},
            ],
            isSubmitted: false,
            isLoading: false
		}
	},
    validations: {
            itemFormBon: {
                camion_id: {
                    required
                },
                dateDebut: {
                    required
                },
                dateFin: {
                    required
                }
            }

    },
    methods :{



exportDox()
{
        let camio = ""
       if(this.itemFormBon.camion_id==0)
        {
        camio="Tous"
        }
        else
        {
        let mm=this.camionsList.findIndex(item => item.id === this.itemFormBon.camion_id)

        camio= this.camionsList[mm].matricul
        }


let datrw =[new TableRow({
            children: [
                new TableCell({
                    width: {
                        size: 1700,
                        type: WidthType.DXA,
                    },
                    verticalAlign: VerticalAlign.CENTER,
                    children: [new Paragraph({
                                  text: "Date",
                                        spacing: {
                                        before: 100,
                                        after: 100
                                    },
                                  heading: HeadingLevel.HEADING_3,
                                  alignment: AlignmentType.CENTER,
                              })],
                }),
                                        new TableCell({
                                            width: {
                                                size: 3000,
                                                type: WidthType.DXA,
                                            },
                                            verticalAlign: VerticalAlign.CENTER,
                                            children: [new Paragraph({
                                                        text: "Matricul",
                                                        heading: HeadingLevel.HEADING_4,
                                                        alignment: AlignmentType.CENTER,
                                                    })],
                                        }),

                new TableCell({
                    width: {
                        size: 2500,
                        type: WidthType.DXA,
                    },
                    verticalAlign: VerticalAlign.CENTER,
                    children: [new Paragraph({
                                  text: "Km D",
                                  heading: HeadingLevel.HEADING_3,
                                  alignment: AlignmentType.CENTER,
                              })],
                }),

                 new TableCell({
                    width: {
                        size: 2500,
                        type: WidthType.DXA,
                    },
                    verticalAlign: VerticalAlign.CENTER,
                    children: [new Paragraph({
                                  text: "Km A",
                                  heading: HeadingLevel.HEADING_3,
                                  alignment: AlignmentType.CENTER,
                              })],
                }),

                new TableCell({
                    width: {
                        size: 2000,
                        type: WidthType.DXA,
                    },
                    verticalAlign: VerticalAlign.CENTER,
                    children: [new Paragraph({
                                  text: "Gaz (L)",
                                  heading: HeadingLevel.HEADING_3,
                                  alignment: AlignmentType.CENTER,
                              })],
                }),

                new TableCell({
                    width: {
                        size: 2000,
                        type: WidthType.DXA,
                    },
                    verticalAlign: VerticalAlign.CENTER,
                    children: [new Paragraph({
                                  text: "Gaz (DH)",
                                  heading: HeadingLevel.HEADING_3,
                                  alignment: AlignmentType.CENTER,
                              })],
                }),



            ],
        })]

this.itemslist.forEach(function(item){

let r = new TableRow({
            children: [
                new TableCell({
                    width: {
                        size: 1700,
                        type: WidthType.DXA,
                    },
                    verticalAlign: VerticalAlign.CENTER,
                    children: [new Paragraph({
                                  text: item.date,
                                  spacing: {
                                            before: 100,
                                            after: 100,
                                            left: 10,
                                            right:10
                                        },
                                  heading: HeadingLevel.HEADING_6,
                                  alignment: AlignmentType.CENTER,
                              })],
                }),

                                        new TableCell({
                                            width: {
                                                size: 3000,
                                                type: WidthType.DXA,
                                            },
                                            verticalAlign: VerticalAlign.CENTER,
                                            children: [new Paragraph({
                                                        text: item.matricul,
                                                        heading: HeadingLevel.HEADING_6,
                                                        alignment: AlignmentType.CENTER,
                                                    })],
                                        }),

                new TableCell({
                    width: {
                        size: 2500,
                        type: WidthType.DXA,
                    },
                    verticalAlign: VerticalAlign.CENTER,
                    children: [new Paragraph({
                                  text: item.kilom_depart.toString(),
                                        spacing: {
                                            before: 10,
                                            after: 10,
                                            left: 10,
                                            right:10
                                        },
                                  heading: HeadingLevel.HEADING_6,
                                  alignment: AlignmentType.CENTER,
                              })],
                }),

                 new TableCell({
                    width: {
                        size: 2500,
                        type: WidthType.DXA,
                    },
                    verticalAlign: VerticalAlign.CENTER,
                    children: [new Paragraph({
                                  text: item.kilom_arrive.toString(),

                                  heading: HeadingLevel.HEADING_6,
                                  alignment: AlignmentType.CENTER,
                              })],
                }),

                 new TableCell({
                    width: {
                        size: 2500,
                        type: WidthType.DXA,
                    },
                    verticalAlign: VerticalAlign.CENTER,
                    children: [new Paragraph({
                                  text: item.carbur_l.toString(),

                                  heading: HeadingLevel.HEADING_6,
                                  alignment: AlignmentType.CENTER,
                              })],
                }),

                new TableCell({
                    width: {
                        size: 2500,
                        type: WidthType.DXA,
                    },
                    verticalAlign: VerticalAlign.CENTER,
                    children: [new Paragraph({
                                  text: item.carbur_dh.toString(),
                                  spacing: {
                                            before: 10,
                                            after: 10,
                                            left: 10,
                                            right:10
                                        },
                                  heading: HeadingLevel.HEADING_6,
                                  alignment: AlignmentType.CENTER,
                              })],
                }),


            ],
        })

  datrw.push(
 r
)
});

//tableau maiteance:
                        let datrwRet =[new TableRow({
                                    children: [
                                        new TableCell({
                                            width: {
                                                size: 1700,
                                                type: WidthType.DXA,
                                            },
                                            verticalAlign: VerticalAlign.CENTER,
                                            children: [new Paragraph({
                                                        text: "Date",
                                                            spacing: {
                                                            before: 100,
                                                            after: 100
                                                        },
                                                        heading: HeadingLevel.HEADING_4,
                                                        alignment: AlignmentType.CENTER,
                                                    })],
                                        }),
                                        new TableCell({
                                            width: {
                                                size: 3000,
                                                type: WidthType.DXA,
                                            },
                                            verticalAlign: VerticalAlign.CENTER,
                                            children: [new Paragraph({
                                                        text: "Matricul",
                                                        heading: HeadingLevel.HEADING_4,
                                                        alignment: AlignmentType.CENTER,
                                                    })],
                                        }),

                                        new TableCell({
                                            width: {
                                                size: 3000,
                                                type: WidthType.DXA,
                                            },
                                            verticalAlign: VerticalAlign.CENTER,
                                            children: [new Paragraph({
                                                        text: "Prix",
                                                        heading: HeadingLevel.HEADING_4,
                                                        alignment: AlignmentType.CENTER,
                                                    })],
                                        }),
                                        
                                        new TableCell({
                                            width: {
                                                size: 4000,
                                                type: WidthType.DXA,
                                            },
                                            verticalAlign: VerticalAlign.CENTER,
                                            children: [new Paragraph({
                                                        text: "Description",
                                                        heading: HeadingLevel.HEADING_4,
                                                        alignment: AlignmentType.CENTER,
                                                    })],
                                        }),



                                    ],
                                })]

                        this.itemslistMaint.forEach(function(itemr){

                        let rr = new TableRow({
                                    children: [
                                        new TableCell({
                                            width: {
                                                size: 1700,
                                                type: WidthType.DXA,
                                            },
                                            verticalAlign: VerticalAlign.CENTER,
                                            children: [new Paragraph({
                                                        text: itemr.date,
                                                        spacing: {
                                                        before: 50,
                                                        after: 50
                                                    },
                                                        heading: HeadingLevel.HEADING_6,
                                                        alignment: AlignmentType.CENTER,
                                                    })],
                                        }),



                                        new TableCell({
                                            width: {
                                                size: 3000,
                                                type: WidthType.DXA,
                                            },
                                            verticalAlign: VerticalAlign.CENTER,
                                            children: [new Paragraph({
                                                        text: itemr.matricul,
                                                        heading: HeadingLevel.HEADING_6,
                                                        alignment: AlignmentType.CENTER,
                                                    })],
                                        }),
                                        new TableCell({
                                            width: {
                                                size: 3000,
                                                type: WidthType.DXA,
                                            },
                                            verticalAlign: VerticalAlign.CENTER,
                                            children: [new Paragraph({
                                                        text: itemr.prix.toString(),
                                                        heading: HeadingLevel.HEADING_6,
                                                        alignment: AlignmentType.CENTER,
                                                    })],
                                        }),

                                        new TableCell({
                                            width: {
                                                size: 5000,
                                                type: WidthType.DXA,
                                            },
                                            verticalAlign: VerticalAlign.CENTER,
                                            children: [new Paragraph({
                                                        text: itemr.description,
                                                        heading: HeadingLevel.HEADING_6,
                                                        alignment: AlignmentType.CENTER,
                                                    })],
                                        }),
                                    ],
                                })

                        datrwRet.push(
                        rr
                        )
                        });

//fin tableau retour


const tablekm = new Table({

    rows:  datrw
})


const tablemaint= new Table({

    rows:  datrwRet
})

const doc = new Document({

    sections: [
        {



            properties: {
                page: {
                    pageNumbers: {
                        start: 1,
                        formatType: PageNumberFormat.DECIMAL,
                    },
                    margin:{
                                left:500,
                                right:500
                            }
                },
            },
                    headers: {
                        default: new Header({
                            children: [
                        new Paragraph({
                                    children: [
                            new TextRun({
                                text: this.entet_pag,
                                color: '636572',
                                bold: true,

                                }),
                                    ],
                                    heading: HeadingLevel.TITLE,
                                    alignment: AlignmentType.CENTER,
                                }),

                        new Paragraph({
                                    children: [
                            new TextRun({
                                text: this.sousentet_pag,
                                color: '636572',
                                bold: false,
                                })

                                    ],
                                border: {
                                bottom: {
                                        color: "636572",
                                        space: 4,
                                        value: "single",
                                        size: 10,
                                    }},
                                    heading: HeadingLevel.HEADING_3,
                                    alignment: AlignmentType.CENTER,
                                }),




                            ],
                        }),
                    },
                    footers: {
                        default: new Footer({
                            children: [

                        new Paragraph({
                                    children: [
                            new TextRun({
                                text: this.pied_pag,
                                color: '636572',
                                bold: false,
                                })

                                    ],
                                border: {
                                top: {
                                        color: "636572",
                                        space: 4,
                                        value: "single",
                                        size: 10,
                                    }},
                                    heading: HeadingLevel.HEADING_3,
                                    alignment: AlignmentType.CENTER,
                                }),

                                new Paragraph({
                                    alignment: AlignmentType.RIGHT,
                                    children: [
                                        new TextRun({
                                            children: ["Page : ", PageNumber.CURRENT],
                                            color: '636572'
                                        }),
                                        new TextRun({
                                            children: [" Sur ", PageNumber.TOTAL_PAGES],
                                            color: '636572'
                                        }),
                                    ],
                                }),
                            ],
                        }),
                    },



            children: [

                new Paragraph({



                    children: [

                        new TextRun({
                        text: "Suivi Des Camions",
                        color: '636572',
                        bold: false,
                        font: "Segoe UI"
                    }),
                    ],

                    heading: HeadingLevel.HEADING_1,
                                        alignment: AlignmentType.CENTER,

                        spacing: {
                            after: 200,
                            before: 200
                        },
                }),




                new Paragraph({


                    children: [

                        new TextRun({
                        text: "Total Gazoil ( L ): "+this.total_l+"    Total Gazoil ( DH ): "+this.total_dh+"    Total Maintenances ( DH ): "+this.total_m,
                        color: '636572',
                        bold: false,
                        font: "Segoe UI"
                    })
                    ],

                    heading: HeadingLevel.HEADING_5,
                    alignment: AlignmentType.CENTER,

                        spacing: {
                            after: 100,
                            before: 100
                        },
                }),
                
                

                new Paragraph({



                    children: [
                        new TextRun({
                        text: "De la part de : ",
                        color: '5d83a0',
                        bold: false,
                        font: "Segoe UI"
                    }),
                        new TextRun({
                        text: this.user_name,
                        color: '636572',
                        bold: false,
                        font: "Segoe UI Light"
                    }),


                    new TextRun({
                        text: "          Camion : ",
                        color: '5d83a0',
                        bold: false,
                        font: "Segoe UI"
                    }),

                        new TextRun({
                        text: camio,
                        color: '636572',
                        bold: false,
                        font: "Segoe UI Light"
                    }),

                    new TextRun({
                        text:"     De : "+this.itemFormBon.dateDebut+" Au "+this.itemFormBon.dateFin,
                        color: '5d83a0',
                        bold: false,
                        font: "Segoe UI"
                    })
                    ],

                    heading: HeadingLevel.HEADING_5,
                                        alignment: AlignmentType.CENTER,

                        spacing: {
                            after: 300,
                            before: 100
                        },
                }),

                new Paragraph({



                    children: [

                        new TextRun({
                        text: "Km / Gazoil",
                        color: '636572',
                        bold: false,
                        font: "Segoe UI"
                    })
                    ],

                    heading: HeadingLevel.HEADING_1,
                    alignment: AlignmentType.CENTER,

                        spacing: {
                            after: 100,
                            before: 100
                        },
                }),


                tablekm,
                
                new Paragraph({



                    children: [

                        new TextRun({
                        text: "Maintenances",
                        color: '636572',
                        bold: false,
                        font: "Segoe UI"
                    })
                    ],

                    heading: HeadingLevel.HEADING_1,
                    alignment: AlignmentType.CENTER,

                        spacing: {
                            after: 100,
                            before: 100
                        },
                }),
                tablemaint,

                
                ]

        }]

});




    Packer.toBlob(doc).then(blob => {

  saveAs(blob, "Suivi Camion.docx");

    });

},






                fetchCentre: function () {


                    //chager les depots:
                    this.isLoading=true;
                    this.$http.get('/camions')
                    .then((result) => {
                                this.camionsList = result.data.data
                                this.isLoading=false;
                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });

        },

                handleSubmitBonCMD(){

                this.isSubmitted = true;

                this.$v.$touch();
                if (this.$v.$invalid) {
                this.flashMessage.show({
                status: 'info',
                title: 'Vérifiez les données et Reésayez !!',
                })
                }
                else 
                {


                this.fetchList(this.itemFormBon.camion_id,this.itemFormBon.dateDebut,this.itemFormBon.dateFin)
                this.fetchListMaint(this.itemFormBon.camion_id,this.itemFormBon.dateDebut,this.itemFormBon.dateFin)

                }


        },

        fetchList: function (idcamion,deb,fin) {
                    this.isLoading=true;
                    
                    this.$http.get('/kiloCamionRapp/'+idcamion+'/'+deb+'/'+fin)
                    .then((result) => {
                                this.itemslist = result.data.data
                                this.isLoading=false;
                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });
        },
        fetchListMaint: function (idcamion,deb,fin) {
                    this.isLoading=true;
                    
                    this.$http.get('/maintCamionRapp/'+idcamion+'/'+deb+'/'+fin)
                    .then((result) => {
                                this.itemslistMaint = result.data.data
                                this.isLoading=false;
                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });
        }

    },
    components: {
              Loading      
    }
}
</script>

<style  scoped>

</style>