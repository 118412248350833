import { render, staticRenderFns } from "./BoitesRapp.vue?vue&type=template&id=3ddf5822&scoped=true&"
import script from "./BoitesRapp.vue?vue&type=script&lang=js&"
export * from "./BoitesRapp.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3ddf5822",
  null
  
)

export default component.exports