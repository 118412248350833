<template>
  <div class="p-4 back_princ">
  
  <div class=" d-flex justify-content-center text-secondary">
   <i class="fas fa-coins fa-4x"></i>
  </div>
  
  <div class=" d-flex justify-content-center text-secondary mb-4">
   <h2 class="font-weight-lighter">Rapports Finance</h2>
  </div>
  
  
  <div class=" row p-4">
  
              <!-- Earnings (Monthly) Card Example -->
              <div v-if="d_gst_tresor==true" class="col">
                <div class="btn card border border-light shadow h-100 py-2" @click="click_compt">
                  <div class="card-body">
                    <div class="row no-gutters align-items-center">
                      <div class="col mr-2">
                        <div class="text-xs font-weight-bold text-secondary text-uppercase">Soldes Des Comptes</div>
                      </div>
                      <div class="col-auto">
                        <i class="fas fa-clipboard-list  fa-2x text-secondary"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
  
  </div>
  
  <div class=" row p-4">
  
  <!-- Earnings (Monthly) Card Example -->
  <div v-if="d_gst_tresor==true" class="col">
    <div class="btn card border border-light shadow h-100 py-2" @click="click_liste_credits">
      <div class="card-body">
        <div class="row no-gutters align-items-center">
          <div class="col mr-2">
            <div class="text-xs font-weight-bold text-secondary text-uppercase">Liste Des Crédits Clients</div>
          </div>
          <div class="col-auto">
            <i class="fas fa-clipboard-list  fa-2x text-secondary"></i>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>


  <div class=" row p-4">
  
              <!-- Earnings (Monthly) Card Example -->
              <div v-if="d_gst_paiem_four==true" class="col">
                <div class="btn card border border-light shadow h-100 py-2" @click="click_four">
                  <div class="card-body">
                    <div class="row no-gutters align-items-center">
                      <div class="col mr-2">
                        <div class="text-xs font-weight-bold text-secondary text-uppercase">Paiements Fournisseurs En Retard</div>
                      </div>
                      <div class="col-auto">
                        <i class="fas fa-coins fa-2x text-secondary"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
  
                          <!-- Earnings (Monthly) Card Example  --> 
              <div v-if="d_gst_paim_client==true" class="col">
                <div class="btn card border border-light shadow h-100 py-2" @click="click_client">
                  <div class="card-body">
                    <div class="row no-gutters align-items-center">
                      <div class="col mr-2">
                        <div class="font-weight-bold text-secondary  text-uppercase ">Paiements Clients / Vendeurs En Retard</div>
                      </div>
                      <div class="col-auto">
                        <i class="fas fa-coins fa-2x text-secondary"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
  
  </div>
  
    </div>
  </template>
  
  <script>
  export default { 
  
    computed: {
  
  
  
    d_gst_tresor() {
        return this.$store.getters.get_gst_tresor;
      },
  
      d_gst_paiem_four() {
        return this.$store.getters.get_gst_paiem_four;
      },
      d_gst_paim_client() {
        return this.$store.getters.get_gst_paim_client;
      },
    },
  
   methods:{
         click_four()
        {
                        //goto first page:
                      this.$router.push({ 
                          name: 'paiemFourRetard'
  
                      });
        },
        click_client()
        {
                         //goto first page:
                      this.$router.push({ 
                          name: 'paiemClitVendRetr'
  
                      });
        },
        click_compt()
        {
                         //goto first page: 
                      this.$router.push({ 
                          name: 'listSoldesCompteRapp'
  
                      });
        },
        click_impAgri()
        {
                         //goto first page:
                      this.$router.push({ 
                          name: 'listimpagriRapp'
  
                      });
        },
        click_paiemagri()
        {
                         //goto first page:
                      this.$router.push({ 
                          name: 'paiemAgriRapp'
  
                      });
        },
        click_Valider_paybanques()
        {
                         //goto first page:
                      this.$router.push({ 
                          name: 'agriBankListVal'
  
                      });
        },
        click_ordre_paie() 
        {
                         //goto first page:
                      this.$router.push({ 
                          name: 'agriOrderPaie'
  
                      });
        },
        click_liste_credits() 
        {
                         //goto first page:
                      this.$router.push({ 
                          name: 'listeCreditClient'
  
                      });
        },

        
  
   }
  }
  </script>
  <style scoped>
  .back_princ{
    background: #eff7fa;
  }
  
  .text_org{
    color: green;
  }
  </style>