<template>
<div>
  
            <div class="rounded-lg shadow border border-white bg-white mb-4">
                        <div class="row justify-content-centre align-items-center">    

              <div class="col">
  





     
                <div class="input-group  border border-white rounded bg-white p-2  justify-content-centre align-items-center text-info">

                <label for="dateDebut" class="mr-sm-2  pt-2 pl-1"> Date :</label>
                    <b-form-datepicker
                    class="mr-sm-2 border rounded"
                    id="datepicker-dateDebut"
                    placeholder="date fin"
                    v-model="itemFormBon.date"
                    :date-format-options="{day: 'numeric' , month: 'numeric',year: 'numeric' }"
                    locale="fr" />




                                    <div class="input-group-prepend ml-4">
                                        <button class="btn  btn-outline-info rounded-pill ml-1"  id="button-addon1" @click="handleSubmitBonCMD()">   
                                        <span class="fa fa-search"></span> Liste
                                        </button>
                                    </div>




                </div>
   





            </div>
                        </div>          
            </div>  



 
      <div class="rounded-lg shadow border border-white bg-white">
            <div class="row justify-content-centre align-items-center">    

            <h4 class="col m-3 font-weight-lighter text-info"> <span class="fa fa-file-alt"></span> Paiements Clients</h4> 

            <h5 class="col ml-2 font-weight-lighter text-primary">  Total DH : {{total_dh}}</h5> 

            
                    <div class="col">

                                    <b-button @click="exportDox" pill size="sm"  class="m-3 float-right" variant="info">
                                        <i class="fa fa-print"></i>
                                        <span> Rapport</span> 
                                    </b-button>

                    </div>

            </div>
       



        <b-table 
        sort-icon-left
        head-variant="light"
        responsive 
        :fields="fields" 
        :items="itemslist"
        >


        </b-table>
      </div>





    <!-- les trucs public -->
    <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true" color="#0179F9" loader='bars'></loading>


</div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import {required} from "vuelidate/lib/validators";
import { HeadingLevel,PageNumber, PageNumberFormat,Footer, Header, Table,TableCell,TableRow,Document, Paragraph,WidthType,VerticalAlign,AlignmentType, Packer, TextRun } from "docx";
import { saveAs } from 'file-saver';

export default {
      computed: {
              entet_pag() {

      if(this.$store.getters.get_entete)
      {
            return this.$store.getters.get_entete;
      }
      else
      {
            return '';
      }
    },
    sousentet_pag() {

      if(this.$store.getters.get_sousentete)
      {
            return this.$store.getters.get_sousentete;
      }
      else
      {
            return '';
      }
    },
    pied_pag() {

      if(this.$store.getters.get_pied)
      {
            return this.$store.getters.get_pied;
      }
      else
      {
            return '';
      }
    },
      total_dh: function() {
      return this.itemslist.reduce((a, b) => +a + +b.montant, 0);
    },


    },
    mounted(){

    },
	data () {
		return {
            itemFormBon: {
                    date:new Date().toJSON().slice(0,10).replace(/-/g,'-'),
            },


            itemslist: [],
            fields: [
				{key: 'code', label: 'Code', sortable: true},
				{key: 'nom_prenom', label: 'Nom Et Prénom', sortable: true},
                {key: 'zone', label: 'Zone', sortable: true},
                {key: 'date', label: 'Date', sortable: true},
                {key: 'date_ech', label: 'Date Ech', sortable: true},
                {key: 'montant', label: 'Montant', sortable: true},
                {key: 'mode_oper', label: 'Mode', sortable: true},
                {key: 'infos_oper', label: 'Infos', sortable: true},
                {key: 'description', label: 'Description', sortable: true},
                {key: 'user', label: 'Utilisateur'},
            ],
            isSubmitted: false,
            isLoading: false
		}
	},
    validations: {
            itemFormBon: {
                date: {
                    required
                }

            }

    },
    methods :{

exportDox()
        {




        let datrw =[new TableRow({
                    children: [
                        new TableCell({
                            width: {
                                size: 1500,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                            
                           children: [
                                            new TextRun({
                                                text: "Nom & Prénom",
                                            color: '0f31b5',
                                            bold: false,
                                            font: "Segoe UI"
                                            }),
                                        ],
                                            spacing: {
                                before: 100,
                                after: 100
                            },
                                        heading: HeadingLevel.HEADING_3,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),



                        new TableCell({
                            width: {
                                size: 1500,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                     
                                                                                children: [
                                            new TextRun({
                                                text: "Zone",
                                            color: '0f31b5',
                                            bold: false,
                                            font: "Segoe UI"
                                            }),
                                        ],
                                        heading: HeadingLevel.HEADING_3,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),

                        new TableCell({
                            width: {
                                size: 1500,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({

                                                                                children: [
                                            new TextRun({
                                                text: "Date",
                                            color: '0f31b5',
                                            bold: false,
                                            font: "Segoe UI"
                                            }),
                                        ],
                                        heading: HeadingLevel.HEADING_3,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),

                                        new TableCell({
                            width: {
                                size: 1500,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                                                                children: [
                                            new TextRun({
                                                text: "Date Ech",
                                            color: '0f31b5',
                                            bold: false,
                                            font: "Segoe UI"
                                            }),
                                        ],
                                        heading: HeadingLevel.HEADING_3,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),
                        
                                        new TableCell({
                            width: {
                                size: 1500,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        
                                                                                children: [
                                            new TextRun({
                                                text: "Montant",
                                            color: '0f31b5',
                                            bold: false,
                                            font: "Segoe UI"
                                            }),
                                        ],
                                        heading: HeadingLevel.HEADING_3,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),
                        
                         new TableCell({
                            width: {
                                size: 1500,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                       
                                                                                children: [
                                            new TextRun({
                                                text: "Mode",
                                            color: '0f31b5',
                                            bold: false,
                                            font: "Segoe UI"
                                            }),
                                        ],
                                        heading: HeadingLevel.HEADING_3,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),
                        
                         new TableCell({
                            width: {
                                size: 1500,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        
                            children: [
                                            new TextRun({
                                               text: "Infos",
                                            color: '0f31b5',
                                            bold: false,
                                            font: "Segoe UI"
                                            }),
                                        ],
                                        heading: HeadingLevel.HEADING_3,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),
                                                
                         new TableCell({
                            width: {
                                size: 2500,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                      
                            children: [
                                            new TextRun({
                                              text: "Description",
                                            color: '0f31b5',
                                            bold: false,
                                            font: "Segoe UI"
                                            }),
                                        ],
                                        heading: HeadingLevel.HEADING_3,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),

                    ],
                })]

        this.itemslist.forEach(function(item){

        let r = new TableRow({
                    children: [
                        new TableCell({
                            width: {
                                size: 1500,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        
                                        children: [
                                        new TextRun({
                                            text: item.nom_prenom,
                                            color: '000000',
                                            bold: true,
                                            }),
                                                ],
                                        spacing: {
                                        before: 50,
                                        after: 50
                                    },
                                        heading: HeadingLevel.HEADING_3,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),

                        new TableCell({
                            width: {
                                size: 1500,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        
                                        children: [
                                        new TextRun({
                                            text: item.zone,
                                            color: '000000',
                                            bold: true,
                                            }),
                                                ],
                                        heading: HeadingLevel.HEADING_3,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),

                        new TableCell({
                            width: {
                                size: 1500,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        
                                        children: [
                                        new TextRun({
                                            text: item.date,
                                            color: '000000',
                                            bold: true,
                                            }),
                                                ],
                                        heading: HeadingLevel.HEADING_3,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),

                        new TableCell({
                            width: {
                                size: 1500,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        
                                        children: [
                                        new TextRun({
                                            text: item.date_ech,
                                            color: '000000',
                                            bold: true,
                                            }),
                                                ],
                                        heading: HeadingLevel.HEADING_3,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),

                        new TableCell({
                            width: {
                                size: 1500,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        
                                        children: [
                                        new TextRun({
                                            text: item.montant.toString(),
                                            color: '000000',
                                            bold: true,
                                            }),
                                                ],
                                        heading: HeadingLevel.HEADING_3,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),

                        new TableCell({
                            width: {
                                size: 1500,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        
                                        children: [
                                        new TextRun({
                                            text: item.mode_oper,
                                            color: '000000',
                                            bold: true,
                                            }),
                                                ],
                                        heading: HeadingLevel.HEADING_3,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),
                        
                        new TableCell({
                            width: {
                                size: 1500,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        
                                        children: [
                                        new TextRun({
                                            text: item.infos_oper,
                                            color: '000000',
                                            bold: true,
                                            }),
                                                ],
                                        heading: HeadingLevel.HEADING_3,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),
                        
                        new TableCell({
                            width: {
                                size: 2500,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        
                                        children: [
                                        new TextRun({
                                            text: item.description,
                                            color: '000000',
                                            bold: true,
                                            }),
                                                ],
                                        heading: HeadingLevel.HEADING_3,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),


                    ],
                })

        datrw.push(
        r
        )
        });

 

        const table = new Table({

            rows:  datrw
        })

const doc = new Document({

    sections: [
        {

                                properties: {
                        page: {
                            pageNumbers: {
                                start: 1,
                                formatType: PageNumberFormat.DECIMAL,
                            },
                            margin:{
                                left:500,
                                right:500
                            }
                        },
                    },
                    headers: {
                        default: new Header({
                            children: [
                        new Paragraph({
                                    children: [
                            new TextRun({
                                text: this.entet_pag,
                                color: '000000',
                                bold: true,

                                }),
                                    ],
                                    heading: HeadingLevel.TITLE,
                                    alignment: AlignmentType.CENTER,
                                }),

                        new Paragraph({
                                    children: [
                            new TextRun({
                                text: this.sousentet_pag,
                                color: '000000',
                                bold: false,
                                font: "Segoe UI"
                                })

                                    ],
                                border: {
                                bottom: {
                                        color: "000000",
                                        space: 4,
                                        value: "single",
                                        size: 10,
                                    }},
                                    heading: HeadingLevel.HEADING_1,
                                    alignment: AlignmentType.CENTER,
                                }),




                            ],
                        }),
                    },
                    footers: {
                        default: new Footer({
                            children: [

                        new Paragraph({
                                    children: [
                            new TextRun({
                                text: this.pied_pag,
                                color: '000000',
                                bold: false,
                                })

                                    ],
                                border: {
                                top: {
                                        color: "000000",
                                        space: 4,
                                        value: "single",
                                        size: 10,
                                    }},
                                    heading: HeadingLevel.HEADING_3,
                                    alignment: AlignmentType.CENTER,
                                }),

                                new Paragraph({
                                    alignment: AlignmentType.RIGHT,
                                    children: [
                                        new TextRun({
                                            children: ["Page : ", PageNumber.CURRENT],
                                            color: '000000'
                                        }),
                                        new TextRun({
                                            children: [" Sur ", PageNumber.TOTAL_PAGES],
                                            color: '000000'
                                        }),
                                    ],
                                }),
                            ],
                        }),
                    },


                    children: [

                        new Paragraph({



                            children: [

                                new TextRun({
                                text: "Paiements Clients",
                                color: '000000',
                                bold: false,
                                font: "Segoe UI"
                            }), 
                                new TextRun({
                                text: "     Date : "+this.itemFormBon.date,
                                color: '000000',
                                bold: false,
                                font: "Segoe UI"
                            })

                            ],

                            heading: HeadingLevel.HEADING_2,
                                                alignment: AlignmentType.CENTER,

                                spacing: {
                                    after: 300,
                                    before: 300
                                },
                        }),




                        table
                        
                        ]


        }]

});









            Packer.toBlob(doc).then(blob => {

        saveAs(blob, "Paiements_Clients_Vendeurs.docx");

            });

        },




        handleSubmitBonCMD(){



                this.isSubmitted = true;

                this.$v.$touch();
                if (this.$v.$invalid) {
                this.flashMessage.show({
                status: 'info',
                title: 'Vérifiez les données et Reésayez !!',
                })
                }
                else 
                {


                this.fetchList(this.itemFormBon.date)
   

                }


        },

        fetchList: function (dt) {
                    this.isLoading=true;
                    
                    this.$http.get('/paiemClientsRetards/'+dt)
                    .then((result) => {
                                this.itemslist = result.data.data
                                this.isLoading=false;
                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });
        }

    },
    components: {
              Loading      
    }
}
</script>

<style  scoped>

</style>