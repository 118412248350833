<template>
<div class="p-4 back_princ">

<div class=" d-flex justify-content-center text-secondary">
 <i class="fas fa-boxes fa-4x"></i>
</div>

<div class=" d-flex justify-content-center text-secondary mb-4">
 <h2 class="font-weight-lighter">Rapports Stock</h2>
</div>




<div class=" row p-4">

            <!-- Earnings (Monthly) Card Example -->
            <div  class="col">
              <div class="btn card border border-light shadow h-100 py-2" @click="click_Art">
                <div class="card-body">
                  <div class="row no-gutters align-items-center">
                    <div class="col mr-2">
                      <div class="text-xs font-weight-bold text-secondary text-uppercase">Stock Articles</div>
                    </div>
                    <div class="col-auto">
                      <i class="fas fa-clipboard-list fa-2x text-secondary"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>


            <!-- Earnings (Monthly) Card Example -->
            <div  class="col">
              <div class="btn card border border-light shadow h-100 py-2" @click="click_valorisation">
                <div class="card-body">
                  <div class="row no-gutters align-items-center">
                    <div class="col mr-2">
                      <div class="text-xs font-weight-bold text-secondary text-uppercase">Valorisation Du Stock</div>
                    </div>
                    <div class="col-auto">
                      <i class="fas fa-clipboard-list fa-2x text-secondary"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          
</div>

<div class=" row p-4">

              <!-- Earnings (Annual) Card Example  -->
            <div v-if="d_gst_art==true" class="col">
              <div class="btn card border border-light shadow h-100 py-2"  @click="click_invent">
                <div class="card-body">
                  <div class="row no-gutters align-items-center">
                    <div class="col mr-2">
                      <div class="text-xs font-weight-bold text-secondary  text-uppercase">Inventaire</div>
                    </div>
                    <div class="col-auto">
                      <i class="fas fa-layer-group fa-2x text-secondary"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Earnings (Annual) Card Example  -->
            <div v-if="d_gst_deplac==true"  class="col">
              <div class="btn card border border-light shadow h-100 py-2"  @click="click_deplac">
                <div class="card-body">
                  <div class="row no-gutters align-items-center">
                    <div class="col mr-2">
                      <div class="text-xs font-weight-bold text-secondary  text-uppercase">Articles (Déplacements)</div>
                    </div>
                    <div class="col-auto">
                      <i class="fas fa-clipboard-list fa-2x text-secondary"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
</div>



<div class=" row p-4">

                        <!-- Earnings (Monthly) Card Example -->
            <div v-if="d_gst_entre==true"  class="col">
              <div class="btn card border border-light shadow h-100 py-2" @click="click_art_entr">
                <div class="card-body">
                  <div class="row no-gutters align-items-center">
                    <div class="col mr-2">
                      <div class="text-xs font-weight-bold text-secondary text-uppercase">Articles (Entrées)</div>
                    </div>
                    <div class="col-auto">
                      <i class="fas fa-clipboard-list fa-2x text-secondary"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Earnings (Monthly) Card Example  -->
            <div v-if="d_gst_sorti==true"  class="col">
              <div class="btn card border border-light shadow h-100 py-2" @click="click_art_sort">
                <div class="card-body">
                  <div class="row no-gutters align-items-center">
                    <div class="col mr-2">
                      <div class="font-weight-bold text-secondary  text-uppercase ">Articles (Sorties)</div>
                    </div>
                    <div class="col-auto">
                      <i class="fas fa-clipboard-list fa-2x text-secondary"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>



</div>

<div class=" row p-4">

<!-- Earnings (Annual) Card Example  -->
<div v-if="d_gst_art==true" class="col">
  <div class="btn card border border-light shadow h-100 py-2"  @click="click_exp">
    <div class="card-body">
      <div class="row no-gutters align-items-center">
        <div class="col mr-2">
          <div class="text-xs font-weight-bold text-secondary  text-uppercase">Lots Expirés</div>
        </div>
        <div class="col-auto">
          <i class="fas fa-layer-group fa-2x text-secondary"></i>
        </div>
      </div>
    </div>
  </div>
</div>
</div>



  </div>
</template>

<script>
export default {
computed:{
      d_gst_art() {
      return this.$store.getters.get_gst_art;
    },
    d_gst_deplac() {
      return this.$store.getters.get_gst_deplac;
    },

    d_gst_entre() {
      return this.$store.getters.get_gst_entre;
    },

    d_gst_sorti() {
      return this.$store.getters.get_gst_sorti;
    },
},
 methods:{
      click_invent()
      {
                      //goto first page:
                    this.$router.push({ 
                        name: 'inventaireRapp'

                    });
      },

      click_valorisation()
      {
                      //goto first page:
                    this.$router.push({ 
                        name: 'valorisationStock'

                    });
      },
      
      click_exp()
      {
                      //goto first page:
                    this.$router.push({ 
                        name: 'listExpirRapp'

                    });
      },
       click_Art()
      {
                      //goto first page:
                    this.$router.push({ 
                        name: 'liststockLotsRapp'

                    });
      },
      click_art_entr()
      {
                        //goto first page:
                    this.$router.push({ 
                        name: 'listEntreesRapp'

                    });
      },
      click_art_sort()
      {
                       //goto first page:
                    this.$router.push({ 
                        name: 'listSortiesRapp'

                    });
      },
      click_deplac()
      {
                        //goto first page:
                    this.$router.push({ 
                        name: 'listDeplacRapp'

                    });
      },


 }
}
</script>
<style scoped>
.back_princ{
  background: #eff7fa;
}

.text_org{
  color: green;
}
</style>