<template>
<div class="p-4 back_princ">

<div class=" d-flex justify-content-center text-info">
 <i class="fas fa-sliders-h fa-4x"></i>
</div>

<div class=" d-flex justify-content-center text-info mb-4">
 <h2 class="font-weight-lighter">Paramètres</h2>
</div>











    <!-- les trucs public -->

    <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true" color="#0179F9" loader='bars'></loading>



  </div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import {required,decimal} from "vuelidate/lib/validators";
export default {
data () {
		return {

            itemForm: {
                    qota_p:'',
                    sur_quota_abonp:'',
                    sur_quota_p:''},

            //public trucs

            isSubmitted: false,
            isLoading: false
		}
	},
    validations: {
            itemForm: {
                qota_p: {
                    required,
                    decimal
                },
                sur_quota_abonp: {
                    required,
                    decimal
                },
               sur_quota_p: {
                    required,
                    decimal
                },
            }
    },
    methods :{



    },
    components: {
              Loading      
    }

}
</script>
<style scoped>
.back_princ{
  background: #eff7fa;
}

.text_org{
  color: green;
}
</style>