<template>
<div>
  
            <div class="rounded-lg shadow border border-white bg-white mb-4">
                        <div class="row justify-content-centre align-items-center">    

              <div class="col">
  





        <form @submit.prevent="handleSubmitBonCMD()">
                <div class="input-group  border border-white rounded bg-white p-2  justify-content-centre align-items-center text-info">

                <label for="vendeur_id" class="mr-sm-2 pt-2"> Vendeur :</label>

                <select class="form-control border rounded" id="vendeur_id" v-model="itemFormBon.vendeur_id"
                        :class="{ 'is-invalid': isSubmitted && $v.itemFormBon.vendeur_id.$error }">

                        <option v-for="item in venteursList" :key="item.id" v-bind:value="item.id">   
                            {{ item.nom_prenom }} - {{ item.code }}
                        </option>

                                            
                    <option value=0>Tous</option>

                </select>


        
                <label for="dateDebut" class="mr-sm-2  pt-2 pl-1"> De :</label>
                    <b-form-datepicker
                    class="mr-sm-2 border rounded"
                    id="datepicker-dateDebut"
                    placeholder="date fin"
                    v-model="itemFormBon.dateDebut"
                    :date-format-options="{day: 'numeric' , month: 'numeric',year: 'numeric' }"
                    locale="fr" />
        

                       
                <label for="dateFin" class="mr-sm-2  pt-2 pl-1"> Au :</label>
                    <b-form-datepicker
                    class="mr-sm-2 border rounded"
                    id="datepicker-dateFin"
                    placeholder="date fin"
                    v-model="itemFormBon.dateFin"
                    :date-format-options="{day: 'numeric' , month: 'numeric',year: 'numeric' }"
                    locale="fr" />
      



                                    <div class="input-group-prepend">
                                        <button class="btn  btn-info rounded-pill ml-1" type="submit" id="button-addon1">   
                                        <span class="fa fa-search"></span> Liste
                                        </button>
                                    </div>

                                

                </div>
        </form>





            </div>
                        </div>          
            </div>  



 
      <div class="rounded-lg shadow border border-white bg-white">
            <div class="row justify-content-centre align-items-center">    

            <h4 class="col ml-3 font-weight-lighter text-info"> <span class="fa fa-file-alt"></span> Suivi Des Boites / Plateaux</h4> 

                    <div class="col">

                                    <b-button pill size="sm"  class="m-2 float-right" variant="info" @click="exportDox">
                                        <i class="fa fa-print"></i>
                                        <span> Rapport</span> 
                                    </b-button>

                                    <b-button pill size="sm"  class="m-2 float-right" variant="light" @click="handleSubmitDetail">
                                        <i class="fa fa-sync-alt"></i>
                                        <span> Détails</span> 
                                    </b-button>
                    </div>
            </div>
       

        <b-table 

        id="main-table"
        :per-page="perPage"
        :current-page="currentPage"
        responsive  
        sort-icon-left
        head-variant="light"

        :fields="fields" 
        :items="itemslist"
        >
      
        </b-table>

                <b-pagination
                pills
                v-model="currentPage"
                :total-rows="rows_pagin"
                :per-page="perPage"
                aria-controls="main-table"
                align="center"
                ></b-pagination>



      </div>



    <!-- le modal -->
    <b-modal id="detailModal" title="Détails" size="lg" hide-footer>


       <b-table 
        small 
        borderless
        striped 
        sticky-header
        :fields="fieldsD" 
        :items="itemslistD"
        >

        
        </b-table>

    </b-modal>

    <!-- les trucs public -->
    <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true" color="#0179F9" loader='bars'></loading>


</div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import {required} from "vuelidate/lib/validators";
import { HeadingLevel,PageNumber, PageNumberFormat,Footer, Header, Table,TableCell,TableRow,Document, Paragraph,WidthType,VerticalAlign,AlignmentType, Packer, TextRun } from "docx";
import { saveAs } from 'file-saver';

export default {
      computed: {
    entet_pag() {

      if(this.$store.getters.get_entete)
      {
            return this.$store.getters.get_entete;
      }
      else
      {
            return '';
      }
    },
    sousentet_pag() {

      if(this.$store.getters.get_sousentete)
      {
            return this.$store.getters.get_sousentete;
      }
      else
      {
            return '';
      }
    },
    pied_pag() {

      if(this.$store.getters.get_pied)
      {
            return this.$store.getters.get_pied;
      }
      else
      {
            return '';
      }
    },
      rows_pagin() {
        return this.itemslist.length
      }

    },
    mounted(){
      this.fetchCentre()
    },
	data () {
		return {
            perPage: 10,
            currentPage: 1,
            itemFormBon: {
                    vendeur_id:'',
                    centre_nom:'',
                    dateDebut:new Date().toJSON().slice(0,10).replace(/-/g,'-'),
                    dateFin:new Date().toJSON().slice(0,10).replace(/-/g,'-'),
                    is_matin:''
            },

            venteursList:[],
            itemslist: [],
            fields: [
				{key: 'nom', label: 'Boite / Plateau', sortable: true},
                {key: 'total_qte_chrg', label: 'Total Charge', sortable: true},
                {key: 'total_qte_dechrg', label: 'Total Décharge', sortable: true},
                {key: 'differ', label: 'Différence', sortable: true}
            ],

            itemslistD: [],
            fieldsD: [
				{key: 'nom', label: 'Boite / Plateau', sortable: true},
                {key: 'bon', label: 'Bon Charge', sortable: true},
                {key: 'qte_charge', label: 'Qte Charge', sortable: true},
                {key: 'qte_decharge', label: 'Qte Décharge', sortable: true},
                {key: 'differ', label: 'Différence', sortable: true}
            ],
 
            isSubmitted: false,
            isLoading: false
		}
	},
    validations: {
            itemFormBon: {
                vendeur_id: {
                    required
                },
                dateDebut: {
                    required
                },
                dateFin: {
                    required
                }
            }

    },
    methods :{

        exportDox()
        {
        let cod=""
        let nom_pren = ""


        if(this.itemFormBon.vendeur_id==0)
        {
        cod="Tous"
        }
        else
        {
        let mm=this.venteursList.findIndex(item => item.id === this.itemFormBon.vendeur_id)
        nom_pren= this.venteursList[mm].nom_prenom
        cod= this.venteursList[mm].code
        }



        let datrw =[new TableRow({
                    children: [
                        new TableCell({
                            width: {
                                size: 5000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                       
                                                                                children: [
                                            new TextRun({
                                             text: "Boite / Plataux",
                                            color: '0f31b5',
                                            bold: false,
                                            font: "Segoe UI"
                                            }),
                                        ],
                                            spacing: {
                                            before: 100,
                                            after: 100
                                        },
                                        color: '0f31b5',
                                        heading: HeadingLevel.HEADING_3,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),


                        new TableCell({
                            width: {
                                size: 2000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                       
                                        children: [
                                            new TextRun({
                                            text: "Total Charge",
                                            color: '0f31b5',
                                            bold: false,
                                            font: "Segoe UI"
                                            }),
                                        ],
                                        heading: HeadingLevel.HEADING_3,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),

                        new TableCell({
                            width: {
                                size: 2000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        
                                        children: [
                                            new TextRun({
                                            text: "Total Décharge",
                                            color: '0f31b5',
                                            bold: false,
                                            font: "Segoe UI"
                                            }),
                                        ],
                                        heading: HeadingLevel.HEADING_3,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),

                                        new TableCell({
                            width: {
                                size: 2000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        
                                        children: [
                                            new TextRun({
                                            text: "Différence",
                                            color: '0f31b5',
                                            bold: false,
                                            font: "Segoe UI"
                                            }),
                                        ],
                                        heading: HeadingLevel.HEADING_3,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),

                    ],
                })]


        this.itemslist.forEach(function(item){

        let r = new TableRow({
                    children: [
                        new TableCell({
                            width: {
                                size: 5000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                       
                                        children: [
                                        new TextRun({
                                            text: item.nom,
                                            color: '000000',
                                            bold: true,
                                            }),
                                                ],
                                                spacing: {
                                                before: 50,
                                                after: 50
                                            },
                                        heading: HeadingLevel.HEADING_3,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),


                        new TableCell({
                            width: {
                                size: 2000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                     
                                        children: [
                                        new TextRun({
                                            text: item.total_qte_chrg.toString(),
                                            color: '000000',
                                            bold: true,
                                            }),
                                                ],
                                        heading: HeadingLevel.HEADING_3,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),

                        new TableCell({
                            width: {
                                size: 2000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        
                                        children: [
                                        new TextRun({
                                            text: item.total_qte_dechrg.toString(),
                                            color: '000000',
                                            bold: true,
                                            }),
                                                ],
                                        heading: HeadingLevel.HEADING_3,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),

                        new TableCell({
                            width: {
                                size: 2000,
                                type: WidthType.DXA,
                            },
                            verticalAlign: VerticalAlign.CENTER,
                            children: [new Paragraph({
                                        
                                        children: [
                                        new TextRun({
                                            text: item.differ.toString(),
                                            color: '000000',
                                            bold: true,
                                            }),
                                                ],
                                        heading: HeadingLevel.HEADING_3,
                                        alignment: AlignmentType.CENTER,
                                    })],
                        }),

                    ],
                })

        datrw.push(
        r
        )
        });



        const table = new Table({

            rows:  datrw
        })

const doc = new Document({

    sections: [
        {


                    properties: {
                        page: {
                            pageNumbers: {
                                start: 1,
                                formatType: PageNumberFormat.DECIMAL,
                            },
                            margin:{
                                left:500,
                                right:500
                            }
                        },
                    },
                    headers: {
                        default: new Header({
                            children: [
                        new Paragraph({
                                    children: [
                            new TextRun({
                                text: this.entet_pag,
                                color: '000000',
                                bold: true,

                                }),
                                    ],
                                    heading: HeadingLevel.TITLE,
                                    alignment: AlignmentType.CENTER,
                                }),

                        new Paragraph({
                                    children: [
                            new TextRun({
                                text: this.sousentet_pag,
                                color: '000000',
                                bold: false,
                                })

                                    ],
                                border: {
                                bottom: {
                                        color: "000000",
                                        space: 4,
                                        value: "single",
                                        size: 10,
                                    }},
                                    heading: HeadingLevel.HEADING_1,
                                    alignment: AlignmentType.CENTER,
                                }),




                            ],
                        }),
                    },
                    footers: {
                        default: new Footer({
                            children: [

                        new Paragraph({
                                    children: [
                            new TextRun({
                                text: this.pied_pag,
                                color: '000000',
                                bold: false,
                                })

                                    ],
                                border: {
                                top: {
                                        color: "000000",
                                        space: 4,
                                        value: "single",
                                        size: 10,
                                    }},
                                    heading: HeadingLevel.HEADING_3,
                                    alignment: AlignmentType.CENTER,
                                }),

                                new Paragraph({
                                    alignment: AlignmentType.RIGHT,
                                    children: [
                                        new TextRun({
                                            children: ["Page : ", PageNumber.CURRENT],
                                            color: '000000'
                                        }),
                                        new TextRun({
                                            children: [" Sur ", PageNumber.TOTAL_PAGES],
                                            color: '000000'
                                        }),
                                    ],
                                }),
                            ],
                        }),
                    },



                    children: [

                        new Paragraph({



                            children: [

                                new TextRun({
                                text: "Suivi Des Boites / Plateaux",
                                color: '000000',
                                bold: false,
                                font: "Segoe UI"
                            }),

                                new TextRun({
                                text: "     De : "+this.itemFormBon.dateDebut+" Au "+this.itemFormBon.dateFin,
                                color: '000000',
                                bold: false,
                                font: "Segoe UI"
                            })
                            ],

                            heading: HeadingLevel.HEADING_2,
                                                alignment: AlignmentType.CENTER,

                                spacing: {
                                    after: 300,
                                    before: 300
                                },
                        }),
                        
                        
                        new Paragraph({


                            children: [

                                new TextRun({
                                text: "     Vendeur : "+nom_pren+" - "+cod,
                                color: '000000',
                                bold: false,
                                font: "Segoe UI"
                            })
                            ],

                            heading: HeadingLevel.HEADING_3,
                                                alignment: AlignmentType.CENTER,

                                spacing: {
                                    after: 300,
                                    before: 300
                                },
                        }),


                        table
                        
                        ]

        }]

});







            Packer.toBlob(doc).then(blob => {

        saveAs(blob, "boites_plateaux.docx");

            });

        },



                fetchCentre: function () {


                    //chager les depots:
                    this.isLoading=true;
                    this.$http.get('/vendeurs')
                    .then((result) => {
                                this.venteursList = result.data.data
                                this.isLoading=false;
                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });

        },

                handleSubmitBonCMD(){

                this.isSubmitted = true;

                this.$v.$touch();
                if (this.$v.$invalid) {
                this.flashMessage.show({
                status: 'info',
                title: 'Vérifiez les données et Reésayez !!',
                })
                }
                else 
                {


                this.fetchList(this.itemFormBon.vendeur_id,this.itemFormBon.dateDebut,this.itemFormBon.dateFin)

                }


        },

        fetchList: function (idvend,deb,fin) {
                    this.isLoading=true;
                    
                    this.$http.get('/suiviBoitesRapp/'+idvend+'/'+deb+'/'+fin)
                    .then((result) => {
                                this.itemslist = result.data.data
                                this.isLoading=false;
                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });
        },
         handleSubmitDetail(){

                this.isSubmitted = true;

                this.$v.$touch();
                if (this.$v.$invalid) {
                this.flashMessage.show({
                status: 'info',
                title: 'Vérifiez les données et Reésayez !!',
                })
                }
                else 
                {


                this.fetchListDetail(this.itemFormBon.vendeur_id,this.itemFormBon.dateDebut,this.itemFormBon.dateFin)

                }


        },
        fetchListDetail: function (idvend,deb,fin) {
                    this.isLoading=true;
                    
                    this.$http.get('/suiviBoitesDetailRapp/'+idvend+'/'+deb+'/'+fin)
                    .then((result) => {
                                this.itemslistD = result.data.data
                                this.isLoading=false;

                                this.$root.$emit('bv::show::modal', 'detailModal')
                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });
        },


    },
    components: {
              Loading      
    }
}
</script>

<style  scoped>

</style>