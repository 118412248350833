<template>
    <div class="p-4 back_princ">
    
    <div class=" d-flex justify-content-center text-info">
     <i class="fas fa-clipboard-list fa-4x"></i>
    </div>
    
    <div class=" d-flex justify-content-center text-info mb-4">
     <h2 class="font-weight-lighter">Rapports Des Ventes Des Points De Vente</h2>
    </div>
    
    
    
    
    <div class=" row p-4">
    
                <!-- Earnings (Monthly) Card Example -->
                <div class="col">
                  <div class="btn card border border-light shadow h-100 py-2" @click="click_list">
                    <div class="card-body">
                      <div class="row no-gutters align-items-center">
                        <div class="col mr-2">
                          <div class="text-xs font-weight-bold text-secondary text-uppercase">Liste Des Ventes POS</div>
                        </div>
                        <div class="col-auto">
                          <i class="fas fa-clipboard-list  fa-2x text-secondary"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
    
    </div>
    
    
    
    
    
    <div class=" row p-4">
    
                <!-- Earnings (Monthly) Card Example -->
                <div class="col">
                  <div class="btn card border border-light shadow h-100 py-2" @click="click_Qte">
                    <div class="card-body">
                      <div class="row no-gutters align-items-center">
                        <div class="col mr-2">
                          <div class="font-weight-bold text-secondary  text-uppercase ">Quantités Des Ventes POS</div>
                        </div>
                        <div class="col-auto">
                          <i class="fas fa-clipboard-list fa-2x text-secondary"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
    
    </div>
    
    
    
      </div>
    </template>
    
    <script>
    export default {
    
     methods:{
           click_list()
          {
                          //goto first page:
                        this.$router.push({ 
                            name: 'rappVentePos'
    
                        });
          },
          click_Qte()
          {
                           //goto first page:
                        this.$router.push({ 
                            name: 'rappQtePos'
    
                        });
          },
    
    
     }
    }
    </script>
    <style scoped>
    .back_princ{
      background: #eff7fa;
    }
    
    .text_org{
      color: green;
    }
    </style>